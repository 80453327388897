<template lang="pug">
v-app-bar#main-navigation(:height="76" :extension-height="98" :absolute="isAbsolute" :scroll-off-screen="$vuetify.breakpoint.xs" elevate-on-scroll :hide-on-scroll="$vuetify.breakpoint.xs" :scroll-threshold="100" :app="$vuetify.breakpoint.xs")
	template(#extension v-if="showMenuExtension")
		v-container.hidden-md-and-up
			top-navigation-search.search-bar--mobile(v-if="showMobileNav" @toggle="toggleMobileNav")
			v-row.mobile-main-nav.justify-space-around
				v-col.pa-0.shrink
					nuxt-link(:to="localePath({name: 'areas'})") skigebiete
				v-divider(vertical dark)
				v-col.pa-0.shrink
					nuxt-link(:to="localePath({name: 'weather'})") wetter
				v-divider(vertical dark)
				v-col.pa-0.shrink
					nuxt-link(:to="localePath({ name: 'contentpage', params: { 0: 'themen'}})") skiurlaub themen
				v-col.pa-0.shrink
					nuxt-link(:to="localePath({ name: 'weblog'})") blog
				v-col.pa-0.shrink
					nuxt-link(:to="localePath({ name: 'news'})") nachrichten

			v-row
				v-toolbar.text-center(flat dense color="white")
					v-app-bar-nav-icon(@click="$emit('shownav')" v-if="$vuetify.breakpoint.xsOnly")
						portal-target(name="drawer-nav-icon")
							v-icon menu
					v-toolbar-title.subtitle-2.font-weight-bold {{title}}
	.mobile-navigation-top.justify-md-start.justify-space-between
		nuxt-link.logo(:to="localePath({name: 'index'})")
			img(src="https://static.wintersport.nl/images/de/logo.svg" alt="Wintersport.nl" height="40")
		top-navigation-search.ml-6.mt-1.hidden-xs-only
		v-btn.mt-3.hidden-sm-and-up(@click="toggleMobileNav" color="accent" depressed icon small)
			v-icon search
	v-spacer.hidden-sm-and-down
	.hidden-sm-and-down
		navigation-group(:to="localePath({name: 'areas'})") Skigebiete
		navigation-group(:to="localePath({name: 'weather'})") Wetter
		navigation-group(:to="localePath({ name: 'contentpage', params: { 0: 'themen'}})") Skiurlaub Themen
		navigation-group(:to="localePath({ name: 'weblog'})") Blog
		navigation-group(:to="localePath({ name: 'news'})") Nachrichten
		navigation-group(v-if="user.isAdmin" :to="localePath({name: 'admin'})")
			template(#button="{ on, attrs }")
				v-btn(href="/admin" icon small fab)
					v-icon shield

</template>

<style lang="sass">
.mobile-main-nav
	background-color: #000
	height: 50px
	.theme--dark.v-divider
		border-color: rgba(255, 255, 255, 0.5)
	div
		a
			color: #fff
	.col
		color: white
		display: block
		font-weight: 700
		text-align: center
		// border-left: 1px solid white
		font-size: 11px
		line-height: 50px

		&:first-child
			border-left: none

.mobile-navigation-top
	padding: 14px 16px
	display: flex
	@media #{map-get($display-breakpoints, 'sm-and-down')}
		width: 100%
	a.logo
		@media #{map-get($display-breakpoints, 'sm-only')}
			margin-left: 150px
	.notifier
		top: 5px
		right: 0

.search-bar--mobile
	position: absolute
	left: 0

#main-navigation
	background-color: hsla(0,0%,100%,.9)
	z-index: 2
	> *
		@extend %window-width
	// hack to remove the whole subnav on scroll
	&.v-app-bar--is-scrolled
		// margin-top: 48px !important

	@media #{map-get($display-breakpoints, 'xs-only')}
		border-bottom: 1px solid var(--v-border-base) !important
		box-shadow: none !important

	.v-btn--active:before
		opacity: 0

	&:after
		// @media #{map-get($display-breakpoints, 'sm-and-up')}
		width: 100%
		bottom: 0
		height: 2px
		background: linear-gradient(-248deg, red, #cc1972)
		content: ''
		display: table
		clear: both
</style>

<script>
import searchbar from '@/components/searchbar.vue';
import NavigationGroup from './navigation-group.vue';
import TopNavigationSearch from './top-navigation-search';

export default {
	name: 'MainNav',
	components: {
		searchbar,
		NavigationGroup,
		TopNavigationSearch
	},
	props: {
		title: {
			type: String,
			default: 'skiportal.de'
		}
	},
	data() {
		return {
			showMobileNav: false,
			mounted: false
		};
	},
	computed: {
		user() {
			return (
				(this.$store.state.user.username && this.$store.state.user) || false
			);
		},
		showMenuExtension() {
			return this.mounted && !this.$vuetify.breakpoint.mdAndUp;
		},
		isAbsolute() {
			return !this.$vuetify.breakpoint.xs && this.$route.path !== '/';
		}
	},
	mounted() {
		this.mounted = true;
	},
	methods: {
		onEnter() {
			this.$router.push(
				this.localePath({
					name: 'search',
					query: { q: '' }
				})
			);
		},
		toggleMobileNav() {
			this.showMobileNav = !this.showMobileNav;
		},
		selectSearch(item) {
			if (!item) return false;

			const toConfig = {
				Area: { name: 'areas-area', params: { area: item.slug } },
				Resort: { name: 'resorts-resort', params: { resort: item.slug } },
				Cluster: {
					name: 'clusters-cluster',
					params: {
						cluster: item.slug
					}
				}
			};
			const to = toConfig[item.__typename];

			if (to) {
				this.$router.push(this.localePath(to));
			}
		},

		selectSearchEnter(term) {
			this.$router.push(
				this.localePath({
					name: 'search',
					query: { q: term }
				})
			);
		}
	}
};
</script>
